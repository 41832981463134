<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading
                        ><span class="red--text">行</span>徳駅前南店</heading
                    >
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >東西線行徳駅徒歩2分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県市川市行徳駅前2丁目12-16 1階</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="top center"
                            height="360px"
                            :src="imageMv"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1630557695705!6m8!1m7!1sRvnWp462xCmKtc9tYi0u6w!2m2!1d35.68159479082797!2d139.9144504100113!3f238.3258284357772!4f5.574338320904417!5f0.7820865974627469"
                            width="100%"
                            height="360px"
                            style="border:0;"
                            allowfullscreen=""
                            loading="lazy"
                        ></iframe>
                        <v-card-text
                            >※Googleストリートビューの画像ではREVOISTの看板が表示されていない場合がありますが実際には店舗前に掲示されています。</v-card-text
                        >
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message :message="this.message" :shopImage="trainerImage" />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">行徳駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            改札を出て右側（交番のあるロータリー）に出ます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            マツモトキヨシ側に向かい、京樽とマツモトキヨシの間の道を進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">3</span>.
                            1つ目の右に曲がる道（マツモトキヨシの建物の裏側）で右に曲がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            道を真っ直ぐ進むと左側にREVOIST行徳駅前南店に到着します。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの7号店です！
行徳駅南口から徒歩2分の駅近のジムです！
新築の綺麗な建物の1階店舗で、気分よくパーソナルトレーニングを受けることができる環境です！
現在、行徳にお住まいの多くのお客様に通っていただいております！
コンテストにも出場しているパ―ソナルトレーナーが、あなたのダイエットやボディメイクなど、分かりやすく説明をしながら、楽しくレッスンします！`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '行徳駅前南店',
            center: {
                lat: 35.6815351323196,
                lng: 139.91439633502512,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.6815351323196,
                        lng: 139.91439633502512,
                    },
                    title: '行徳駅前南店',
                },
            ],
            message: `パーソナルジム REVOISTの7号店です！<br />
行徳駅南口から徒歩2分の駅近のジムです！<br />
新築の綺麗な建物の1階店舗で、気分よくパーソナルトレーニングを受けることができる環境です！<br />
現在、行徳にお住まいの多くのお客様に通っていただいております！<br />
コンテストにも出場しているパ―ソナルトレーナーが、あなたのダイエットやボディメイクなど、分かりやすく説明をしながら、楽しくレッスンします！<br /><br />


お客様の体調や体力に合わせて全力で寄り添いサポートします！ 心身ともに健康を目指して一 緒にトレーニング頑張りましょう！<br />
行徳駅前南店トレーナー：畑中司`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '行徳駅前南店',
                    disabled: true,
                    href: 'access-7',
                },
            ]
        },

        imageMv() {
            return 'static/pc_access_7/gyotokuminami-1.jpg'
        },
        accessA() {
            return 'static/pc_access_7/gyotokuminami-2-squashed.jpg'
        },
        accessB() {
            return 'static/pc_access_7/gyotokuminami-3-squashed.jpg'
        },
        accessC() {
            return 'static/pc_access_7/gyotokuminami-4-squashed.jpg'
        },
        accessD() {
            return 'static/pc_access_7/gyotokuminami-5-squashed.jpg'
        },

        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/GyotokuEkimaeMinami.jpg'
                : 'static/landing/GyotokuEkimaeMinami.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/行徳駅前南店　畑中司トレーナー.jpg'
                : 'static/landing/trainer/行徳駅前南店　畑中司トレーナー.jpg'
        },
    },
}
</script>
<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
